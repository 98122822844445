 @use "global";
 @use "variables";

 .homepage-header{
    height: 100;
    width:inherit;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-left : 20px;
    padding-right: 20px;
    padding-top : 10px;
    padding-bottom : 10px;
    align-items: center;
    border-bottom: 2px solid black;
}
.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.remi-text{
    margin: auto;
    font-size: x-large;
    color: map-get(global.$color, blue-4);
}

.bold-remi{
    font-weight: bold;
    color: map-get(global.$color, blue-2);
}

.btn-login{
    background-color: map-get(global.$color, blue-7);
    border-radius : 5px; 
    padding-left: 15px;
    padding-right: 15px;
}

.login-header{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

.logo{
    max-height: 40px;
    max-width: 150px;
}

#warning-container{
    margin-top: 2em;
}

 #warning-DSI{
    width: 50%;
    border: 1px solid black;
    text-align: justify;
    border-radius: 1em;
    background-color: rgba(123,123,123,.2);
    
    padding:1em;
    margin: auto;

}

#menuViewers-btns{
    
    display: flex;
    flex-direction: column;

    padding: .62em;   
    justify-content: space-evenly;
    
}

.warning-text{
    color: red;
    text-align: center;
}

 .bold{
    font-weight: bold;
}

.header-description{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ligne-header{
    max-height: 40px;
    max-width: 150px;
    padding-right: 20px;
}

.highlighted{
    outline: 5px solid rgb(255, 255, 0);
    border-radius: 1em;
    padding:1em;
    margin: auto;
}
@media (min-width : 600px) {
    .homepage-header{
        flex-direction: row;
        justify-content: space-between;
    }
    .remi-text{
        font-size: xx-large;
    }
}
