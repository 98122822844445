@use "global";
@use "variables";

html {
    font-family: "Proxima Nova", sans-serif, verdana, arial;
    background-color: #f2f2f2;
}

.remi-container {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.logo {
    max-height: 40px;
    max-width: 150px;
}

.searchbar-container {
    display: flex;
    flex-direction: row;
    height: 50%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.popup-box {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0px;
    top: 0px;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.secondary-mop-box--actif {
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);

    /* Black w/ opacity */
    #secondary-mop-wrapper {
        width: 90%;
        margin: auto;
    }

    #secondary-mop-content {
        padding-top: 0px;
        margin: auto;
        border: 1em solid map-get(global.$color, blue-9);
    }

    #secondary-mop-header {
        color: white;
        padding-block-end: 2px;
        background-color: map-get(global.$color, blue-9);
        font-weight: bold;
        font-size: big;
    }

    #secondary-mop-footer {
        background-color: map-get(global.$color, blue-9);
    }
}

.popup-header {
    border-bottom: 2px solid black;
    padding-top: .6em;
}

.popup-wrapper {
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    border: 1px solid #888;
    width: 75%;
    /* Could be more or less, depending on screen size */
    text-align: center;
    color: white;

}

.popup-footer {
    border-top: 2px solid black;
    padding: .6em;
    font-weight: bold;
    text-align: right;
}

#logout-wrapper {
    background-color: #fefefe;
    margin: 15% auto;
    /* 15% from the top and centered */
    border: 2px solid black;
    border-radius: 10px;
    width: 45%;
    /* Could be more or less, depending on screen size */
    text-align: center;
    color: white;

}

#logout-footer {
    padding: .6em;
    font-weight: bold;
    text-align: center;
}

#btnCancelLogout {
    background-color: map-get(global.$color, blue-4);
}

#point-numero {
    font-size: large;
    font-weight: bold;
    text-align: center;
}

#point-message {
    padding: 1em;
    background-color: white;
    color: black;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.panel-titre {
    color: white;
    flex-grow: 4;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px #000;

}

.cliquable {
    cursor: pointer;
}

#container-mop {
    color: white;
    display: flex;
    justify-content: center;

}

.panel-mop {
    width: 60%;
    /*rajouter le max en fonction de la taille - si grand écran, réduire le pourcentage*/
    color: black;
    margin: auto;
    background-color: floralwhite;
}

#main-mop-wrapper {
    width: 90%;
}

.mop-buttons-container {
    font-size: x-small;
    display: flex;
    width: inherit;
    flex-direction: row;
    justify-content: space-between;
}

.mop-button {
    border-radius: .62em;
    color: #FFF;
    font-size: x-small;
    font-weight: bold;

    &:hover {
        color: #FFF;
        font-weight: bold;
        box-shadow: 0 .75em 1em 0 rgba(0, 0, 0, 0.24), 0 1em 3.12em 0 rgba(0, 0, 0, 0.19);
        transition: .3s;
    }

    &--red {
        background-color: map-get(global.$color, red-3);
    }

    &--green {
        background-color: map-get(global.$color, green-3);
    }

    &--blue {
        background-color: map-get(global.$color, blue-3);
    }

    &--yellow {
        background-color: map-get(global.$color, yellow-3);
    }

}

.mop-button-secondary {
    &--red {
        background-color: map-get(global.$color, red-9);
    }

    &--green {
        background-color: map-get(global.$color, green-9);
    }

    &--blue {
        background-color: map-get(global.$color, blue-9);
    }

    &--yellow {
        background-color: map-get(global.$color, yellow-9);
    }
}

.panel-arborescence {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    min-height: 1em;
    align-content: center;
    display: flex;
    justify-content: inherit;
    font-size: x-small;
    width: 100%;
    // background-color: map-get(global.$color, blue-3);
    position: sticky;
    top: 0;

    &--red {
        background-color: map-get(global.$color, red-3);
    }

    &--green {
        background-color: map-get(global.$color, green-3);
    }

    &--blue {
        background-color: map-get(global.$color, blue-3);
    }

    &--yellow {
        background-color: map-get(global.$color, yellow-3);
    }
}

.arborescence-list {
    list-style-type: none;
    padding-left: 0;

    &:last-child {
        color: yellow;
    }
}

.arborescence-list-item {
    display: inline-block;
    color: white;

    &::before {
        content: "→";
        font-weight: bold;
        padding: 0 .3em;
    }

    &:first-child::before {
        content: "";
    }

    &:last-child {
        color: yellow;
        font-weight: bold;

    }
}

.menu-btns {

    button,
    a {
        border-radius: .62em;

        &:hover {
            color: white;
            box-shadow: 0 .75em 1em 0 rgba(0, 0, 0, 0.24), 0 1em 3.12em 0 rgba(0, 0, 0, 0.19);
            transition: .3s;
        }
    }

    display: grid;
    padding: .62em;
    grid-template-columns: repeat(3, 1.5fr);
    column-gap: .62em;
    row-gap: .62em;
    justify-content: space-around;
}

#menu-btns-NRJ {

    button,
    a {
        border-radius: .62em;
        color: black;

        &:hover {
            color: white;
            box-shadow: 0 .75em 1em 0 rgba(0, 0, 0, 0.24), 0 1em 3.12em 0 rgba(0, 0, 0, 0.19);
            transition: .3s;
        }
    }

    display: grid;
    padding: .62em;
    grid-template-columns: repeat(3, 2fr);
    column-gap: .62em;
    row-gap: .62em;
    justify-content: space-around;
}

.menu-wrapper {
    width: 85%;
}

.menu-panel {
    display: flex;
    flex-direction: column;
    width: 100%;

    div h3 {
        text-align: center;
    }
}

.menu-mops-list {
    padding: 0px;
    list-style: none;
    color: black;
    border: 2px solid navy;
}

.menu-mops-list-item {
    font-size: small;
    padding: 0px;
    border-bottom: 1px solid black;
    transition: .25s;
    font-weight: bold;
    
    &--important{
        background-color: variables.$color--important;
        &:hover {
            background-color: map-get(global.$color, red-3);
            color: white;
        }
    }
    &--blue {
        background-color: map-get(global.$color, blue-9);
        &:hover {
            background-color: map-get(global.$color, blue-4);
            color: white;
        }
    }
    &--red {
        background-color: map-get(global.$color, red-11);
        &:hover {
            background-color: map-get(global.$color, red-4);
            color: white;
        }
    }
    &--green {
        background-color: map-get(global.$color, green-11);
        &:hover {
            background-color: map-get(global.$color, green-4);
            color: white;
        }
    }
    &--yellow {
        background-color: map-get(global.$color, yellow-10);
        &:hover {
            background-color: map-get(global.$color, yellow-4);
            color: white;
        }
    }
}


#rf-panel {

    background-color: #fff;
    border-left: 1.25em solid aquamarine;
    border-right: 1.25em solid aquamarine;
}

#rf-footer {
    background-color: aquamarine;

}

#rf-header {
    padding: 1.2em;
    color: black;
    font-weight: bold;
    background-color: aquamarine;


}

.btnCPE-actif {
    &::after {
        content: " !!";
    }
}

.wrapper {
    margin: auto;
    display: none;

    &--actif {
        display: flex;
        flex-direction: column;
    }
}

.viewer-wrapper {
    color: black;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.title-secondary-mop {
    font-weight: bold;
    width: 100%;
    background-color: #01fefe;
    text-align: center;
    margin: 0px;
    padding-top: .62em;
}

.secondary-viewer-wrapper {
    background-color: #fff;
    border: #01fefe 5px dashed;
    color: black;
    min-width: 70%;
    max-width: 80%;
    display: flex;
    justify-content: center;
}

.viewer-container {
    width: inherit;
    display: flex;
    flex-direction: column;

}

@media (min-width : 420px) {
    .menu-btns {
        grid-template-columns: repeat(5, 1.5fr);
    }

}


@media (min-width : 750px) {

    .menu-btns {
        >button {
            font-size: medium;
        }
    }

    .menu-panel {
        padding: 5px;
        display: grid;
        grid-auto-columns: max-content;
        grid-auto-flow: row;
        column-gap: 10px;
        grid-template-columns: auto auto;
    }

    .mop-buttons-container {
        font-size: medium;
    }

    .mop-button {
        font-size: medium;
    }

    #main-mop-wrapper {
        width: 65%;
    }

    #secondary-mop-wrapper {
        max-width: 60%;
    }

    .panel-arborescence {
        font-size: small;
    }

}

@media (min-width : 950px) {

    .menu-panel {
        padding: 10px;
        grid-template-columns: auto auto auto;
    }

    .searchbar-container {
        width: 100%;

    }
}