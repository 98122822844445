$bg: floralwhite;
$bgPointSecu : #f44336;
$bgPCCHover : rgb(45, 114, 60);
$bgLigneHover : rgb(45, 114, 60);
$bgIOSHover : rgb(109, 116, 22);

$color--important : #e45e62;
$color--white : #F1FAEE;
$color--beige : #E9D8A6;
$color--yellow : #EE9B00;
$color--orange : #CA6702;
$color--dark-orange : #CA6702;
