@use "variables";

$color: (
  blue-1: #012A4A,
  blue-2: #013A63,
  blue-3: #01497C,
  blue-4: #014F86,
  blue-5: #2A6F97,
  blue-6: #2C7DA0,
  blue-7: #468FAF,
  blue-8: #61A5C2,
  blue-9: #7ac4e4,
  blue-10: #84bacc,

  red-1: #240902,
  red-2: #37040E,
  red-3: #620D14,
  red-4: #5A002C,
  red-5: #7D0E13,
  red-6: #a92831,
  red-7: #A9002C,
  red-8: #8d0707,
  red-9: #a92121,
  red-10: #7b1919,
  red-11: #f9b9b9,

  yellow-1: #F99D1C,
  yellow-2: #EAA103,
  yellow-3: #d59305,
  yellow-4: #C18504,
  yellow-5: #B78822,
  yellow-6: #B7860B,
  yellow-7: #CA9510,
  yellow-8: #CE9F28,
  yellow-9: #D9B339,
  yellow-10: #D9BE66,

  green-1: #051c02,
  green-2: #112d0d,
  green-3: #162614,
  green-4: #293527,
  green-5: #0a3205,
  green-6: #17450f,
  green-7: #274721,
  green-8: #145a07,
  green-9: #2b6021,
  green-10: #445a40,
  green-11: #95d08a,
);


header {
    font-family: Verdana, Geneva, Tahoma, sans-serif;

    svg {
        max-width: fit-content;
        max-height: fit-content;
    }

    max-width: 1280px;
    max-height: 10%;
    display : flex;
    margin : auto;

}

body {
    max-width: 1440px;

    margin: auto;
    background-color: variables.$bg;
    box-shadow: 2px 2px 3px 3px black;
    padding-bottom: 10px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

button {
    background-color: rgb(123, 146, 159);
    color: variables.$color--white;
    margin-top: .31em;
    font-size: small;
    border-radius: .62em;
    padding: .31em .62em;
    cursor: pointer;

    &:hover {
        color: black;
        box-shadow: 0 .75em 1em 0 rgba(0, 0, 0, 0.24), 0 1em 3.12em 0 rgba(0, 0, 0, 0.19);
        transition: .25s;
    }
}

.imgMPL16 {
    display: flex;
    justify-content: center;

    img {
        max-width: 50%;
    }
}

.centered-div {
    text-align: center;

}

.ges-not-concerned {
    background-color: gray;

}

footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    color: darkgrey;
    font-size: 12px;

    div {
        text-align: center;

        a {
            text-decoration: none;
            color: gray;

            &:hover {
                color: black;
                text-decoration: underline;
                transition: .3s;
            }
        }

        #mail {
            color: gray;
        }
    }
}

@for $i from 1 through 10 {
    .btn-#{$i} {
        &--red {
            background-color: map-get($color, red-#{$i});
        }

        &--yellow {
            background-color: map-get($color, yellow-#{$i});
        }

        &--green {
            background-color: map-get($color, green-#{$i});
        }

        &--blue {
            background-color: map-get($color, blue-#{$i});
        }
    }
}

.btn-important {
    background-color: variables.$color--important;
}

.btn-energie {
    background-color: variables.$color--yellow;
}

@media (min-width : 750px) {
    button {
        font-size: medium;
    }
}